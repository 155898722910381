import Calculator from './containers/Calculator'

export default function Home () {
  return (
    <div>
      <h1>Welcome</h1>

      <p>Welcome to the React ❤️ django-allauth demo. It demonstrates:
        <ul>
          <li><a target='_blank' href='https://codeberg.org/allauth/django-allauth/src/branch/main/examples/react-spa/frontend' rel='noreferrer'>React app</a></li>
          <li>A demo API, allowing <i>authenticated</i> users to add up two numbers, backed by two implementations: Django REST framework, and Ninja.
          </li>
        </ul>
      </p>

      <Calculator />
    </div>
  )
}
