import { useUser } from './auth'
import Calculator from './containers/Calculator'

export default function Dashboard () {
  const user = useUser()
  return (
    <div>
      <h1>Dashboard</h1>
      <p>Welcome user {user.display}!</p>

      <Calculator />
    </div>
  )
}
